import { ApiConnector, GET, POST, DELETE, PUT } from './request.service';

export default (() => {
    const API = ApiConnector('/group');
    return {
        GroupsList: async (data, query) =>
            await API(GET, 's', false, query).call(data),
        GroupsListSelect: async (data, query) =>
            await API(GET, 's/select', false, query).call(data),
        SingleGroup: async (data, query) =>
            await API(GET, 's/single', false, query).call(data),
        AddGroup: async (data, query) =>
            await API(POST, 's', false, query).call(data),
        getPupilsOfGroups: async (data, query, params) =>
            await API(GET, `s/${params.group_id}/students`, false, query).call(
                data
            ),
        EditGroup: async (data, query) =>
            await API(PUT, '', false, query).call(data, true),
        DeleteGroup: async (data, query) =>
            await API(DELETE, 's', false, query).call(data),
        InitGroupHW: async (data, query) =>
            await API(POST, '/init-hw', false, query).call(data),
        simpleGetStudent: async (data, query, params) =>
            await API(
                GET,
                `s/${params.group_id}/students/reduced`,
                false,
                query
            ).call(data),
        getGroupHistory: async (data, query) =>
            await API(GET, `/history`, false, query).call(data),
        getGroupHistoryHw: async (data, query) =>
            await API(GET, `/history/hw`, false, query).call(data),
        GetGroupsByOffice: async (query) =>
            await API(GET, '/school', false, query).call(),
        ChangeEqualizerState: async (data) =>
            await API(POST, '/equealizer').call(data),
    };
})();
